import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../../components/seo';
import ConfiguratorHome from '../../screens/configurator';
// import ReinsuranceBanner from '../../components/eshop/ReinsuranceBanner';

import trads from '../../screens/configurator/trads';
import { getBrands } from './utils';
import { pushEvent } from '../../utils/gtm';
import { useLocale } from '../../services/i18n/useLocale';

const ConfiguratorBrandModelScreen = (props) => {
  const { data, pageContext } = props;
  const intl = useLocale();
  const brands = getBrands(data.brands.nodes);
  const brand = brands.find((b) => b.id === pageContext.brandId);

  const title = intl.formatMessage(trads.configuratorModelTitle, {
    lineBreak: null,
    model: brand ? brand.name : '',
  });

  const models = data.models.nodes
    .reduce((res, item) => {
      const { vehicle, slug } = item;
      const { model, yearrange } = vehicle;

      let existing = res.find((i) => i.name === model);

      const year = {
        id: slug,
        from: parseInt(yearrange.from, 10) || undefined,
        to: parseInt(yearrange.to, 10) || undefined,
      };

      if (!existing) {
        res.push({
          id: model,
          name: model,
          years: [year],
        });
      } else {
        existing.years.push(year);
      }

      return res;
    }, [])
    .sort((a, b) => `${a.name}`.toLowerCase().localeCompare(b.name));

  if (brand) {
    pushEvent({
      brand: brand.name,
    });
  }

  return (
    <>
      <Seo
        title={intl.formatMessage(trads.configuratorModelTitle, {
          lineBreak: '',
          model: brand ? brand.name : '',
        })}
      />
      <ConfiguratorHome
        brands={brands}
        title={title}
        rootPath={pageContext.rootPath}
        currentBrandId={pageContext.brandId}
        currentModeName={pageContext.modelName}
        models={models}
      />
      {/* <ReinsuranceBanner
        border
      /> */}
    </>
  );
};

export const query = graphql`
  query ConfiguratorBrandModel($brandId: String!, $cmsLocale: String!) {
    brands: allConfiguratorBrandsJson {
      nodes {
        id: jsonId
        name
      }
    }
    models: allConfiguratorModelsJson(
      filter: {
        vehicle: { brandId: { eq: $brandId } }
        locale: { eq: $cmsLocale }
      }
    ) {
      nodes {
        slug
        vehicle {
          model
          yearrange {
            from
            to
          }
        }
      }
    }
  }
`;

export default React.memo(ConfiguratorBrandModelScreen);
